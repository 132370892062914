
   
<section class="login__container">
       
        <section class="row">
            <section class="login__form">
        
                <br>
                
    
    
                <div class="col-md-12">
                    <form [formGroup]="loginForm" (ngSubmit)="userLogin()">
                      
    
                        <div class="login__subheading mb-3">Claim Management Solution</div>
    
                        <div class="form-group row">
                            <div class="col-md-9"></div>
                            <div class="col-md-3 ; center_class">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" formControlName="companyId"
                                        placeholder="Company ID" aria-label="Email" aria-describedby="Email" autofocus
                                        disabled [ngClass]="{ 'is-invalid': loginCheck && f.companyId.errors }">
                                    <div *ngIf="loginCheck && f.companyId.errors" class="invalid-feedback">
                                        <div *ngIf="f.companyId.errors.required">Company ID is required</div>
                                    </div>
                                </div>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" formControlName="user" placeholder="Login ID"
                                        aria-label="Email" aria-describedby="Email" autofocus
                                        [ngClass]="{ 'is-invalid': loginCheck && f.user.errors }">
                                    <div *ngIf="loginCheck && f.user.errors" class="invalid-feedback">
                                        <div *ngIf="f.user.errors.required">Login ID is required</div>
                                    </div>
                                </div>
    
                                <div class="input-group mb-3">
                                    <input type="password" class="form-control" formControlName="password"
                                        placeholder="Password" aria-label="Password" aria-describedby="Password"
                                        [ngClass]="{ 'is-invalid': loginCheck && f.password.errors }">
                                    <div *ngIf="loginCheck && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                    </div>
                                </div>
    
                                <div class="text-right mb-3">
                                    <button type="submit" class="btn btn-primary cursor-pointer">Login</button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="button" (click)="forgot()"
                                        class="btn btn-primary cursor-pointer">Forgot</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
             
            </section>
        </section>
    </section>






<!-- ------------------------------------cogs --------------------------------- -->





<!-- <section class="login__container">
       
    <section class="row">
        <section class="login__form">
            <div class="login__heading">
     
            </div>
            <br>
            <div class="login__subheading mb-5">Task Management Solutions</div>
            <div class="paraHeading mb-3" >
                Developed By www.powererp.in <br/> (V 5.1)</div>
               
            <div class="col-md-12 text-center">
                <p class="sub-title">Please Log in with your Username</p>
            </div>



            <form class="col-md-12" [formGroup]="loginForm" (ngSubmit)="userLogin()">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="companyId" placeholder="Company ID"
                        aria-label="Email" aria-describedby="Email" autofocus disabled
                        [ngClass]="{ 'is-invalid': loginCheck && f.companyId.errors }" > 
                    <div *ngIf="loginCheck && f.companyId.errors" class="invalid-feedback">
                        <div *ngIf="f.companyId.errors.required">Company ID is required</div>
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="user" placeholder="Login ID"
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.user.errors }">
                    <div *ngIf="loginCheck && f.user.errors" class="invalid-feedback">
                        <div *ngIf="f.user.errors.required">Login ID is required</div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input type="password" class="form-control" formControlName="password" placeholder="Password"
                        aria-label="Password" aria-describedby="Password"
                        [ngClass]="{ 'is-invalid': loginCheck && f.password.errors }">
                    <div *ngIf="loginCheck && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>

                <div class="text-right mb-3">
                    <button type="submit" class="btn btn-primary cursor-pointer">Login</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" (click)="forgot()" class="btn btn-primary cursor-pointer">Forgot</button>
                </div>
            </form>
         
        </section>
    </section>
</section> -->
 <!-- 4.7(gps)  update 22 -april-2023-->
 <!--(blur)="getCompany()"-->
 <!-- (blur)="getLoginId()"-->
            <!-- <img src="../../assets/images/logo.png" alt=""  class="logo"  />  -->

